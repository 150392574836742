import React, { useState } from 'react';

export const MobileNavContext = React.createContext({});

const MobileNavProvider = (props: any) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MobileNavContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState: any) =>
          setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MobileNavContext.Provider>
  );
};

export default MobileNavProvider;