import React from "react"
import { Link } from "gatsby"
import Header from  "./Header"
import PropTypes from 'prop-types'
import Footer from "./Footer"

function Layout(props) {
  return (
    <div className="flex flex-col h-screen bg-background text-text w-full">
      <Header />

      <div className='bg-transparent pb-28' />

      {props.children}

      <div className='grow mt-0'></div>
      <div className="md:max-w-7l place-self-center bg-secondary mt-10 w-full"><Footer /></div>
    </div>
  )
}

Layout.propTypes = {}

export default Layout


// const Layout = ({ location, title, children }) => {
//   return (
//     <div className="" >
//       <Headers />
//       <main>{children}</main>
//       <footer>
//         © {new Date().getFullYear()}, Built with
//         {` `}
//         <a href="https://www.gatsbyjs.com">Gatsby</a>
//       </footer>
//     </div>
//   )
// }

// export default Layout
