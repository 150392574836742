import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import { slide as Menu } from "react-burger-menu"
import { MobileNavContext } from "../context/MobileNavContext"

function showSettings(event) {
  event.preventDefault()
}

// https://github.com/negomi/react-burger-menu
// https://www.freecodecamp.org/news/what-is-lifting-state-up-in-react/
function SingleHamburgerText(label: string, link: string, ctx) {
  return (
    <Link
      to={link}
      className="pb-3 font-sans font-semibold tracking-widest text-white no-underline"
      //@ts-ignore
      onClick={ctx.toggleMenu}
    >
      {label}
    </Link>
  )
}

const MenuMobile = props => {
  const ctx = useContext(MobileNavContext)
  var styles = {
    bmBurgerButton: {
      position: "relative",
      width: "19px",
      height: "15px",
    },
    bmBurgerBars: {
      background: "#000000",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "36px",
      width: "36px",
    },
    bmCross: {
      background: "#ffffff",
    },
    bmMenuWrap: {
      position: "fixed",
      top: 0,
      height: "100%",
    },
    bmMenu: {
      background: "#000000",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
      zIndex: 50,
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    // bmItem: {
    //   display: 'inline-block'
    // },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  }
  return (
    <Menu
      //@ts-ignore
      isOpen={ctx.isMenuOpen}
      //@ts-ignore
      onStateChange={state => ctx.stateChangeHandler(state)}
      styles={styles}
      right
      width={"80%"}
    >
      <div className="h-10" />
      {SingleHamburgerText("home", "/", ctx)}
      {SingleHamburgerText("trải nghiệm", "/trai-nghiem/", ctx)}
      {SingleHamburgerText("tâm linh", "/tam-linh/", ctx)}
      {SingleHamburgerText("khoa học", "/khoa-hoc/", ctx)}
      {SingleHamburgerText("sống đẹp", "/song-dep/", ctx)}
    </Menu>
  )
}
export default MenuMobile
