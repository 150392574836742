// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
// import "./src/normalize.css"
// custom CSS styles
// import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import './src/styles/tailwind.css'
import React from 'react';
import ProviderScope from './src/context/ProviderScope'
import Layout from './src/components/Layout'

export function wrapRootElement({ element }) {
    return <ProviderScope>{element}</ProviderScope>;
}

export function wrapPageElement({ element, props }) {
    // const Layout = element.type.Layout ?? React.Fragment;
    return <Layout {...props}>{element}</Layout>;
}