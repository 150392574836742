import React from 'react'

function Footer() {
  return (
    <div className='flex place-items-center place-content-center py-5'>
        @ {new Date().getFullYear()} Bọ rùa tỉnh thức
    </div>
  )
}

export default Footer