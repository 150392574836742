exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-khoa-hoc-tsx": () => import("./../../../src/pages/khoa-hoc.tsx" /* webpackChunkName: "component---src-pages-khoa-hoc-tsx" */),
  "component---src-pages-others-tsx": () => import("./../../../src/pages/others.tsx" /* webpackChunkName: "component---src-pages-others-tsx" */),
  "component---src-pages-song-dep-tsx": () => import("./../../../src/pages/song-dep.tsx" /* webpackChunkName: "component---src-pages-song-dep-tsx" */),
  "component---src-pages-tam-linh-tsx": () => import("./../../../src/pages/tam-linh.tsx" /* webpackChunkName: "component---src-pages-tam-linh-tsx" */),
  "component---src-pages-trai-nghiem-tsx": () => import("./../../../src/pages/trai-nghiem.tsx" /* webpackChunkName: "component---src-pages-trai-nghiem-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

