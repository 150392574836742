import React from "react"
import { Link } from "gatsby"
import Hamburger from "./Hamburger"
import menuIcon from "../images/menu-icon.svg"

function Header() {
  return (
    <div className="fixed top-0 left-0 flex h-20 z-50 w-screen place-items-center place-content-between px-3 sm:px-5 md:px-10 xl:px-16 2xl:px-24 bg-secondary border-b">
      <Link to="/" className="font-bold text-2xl">
        Bọ rùa <span className='text-accent'>tỉnh</span> thức
      </Link>
      <div className="hidden flex-row gap-12 xl:flex text-xl ">
        <Link to="/">
          <img src={menuIcon} width={24} height={24} />
        </Link>
        <Link to="/trai-nghiem/" activeClassName="font-semibold bg-third px-3 rounded-3xl" className="transition-all duration-500">trải nghiệm</Link>
        <Link to="/tam-linh/" activeClassName="font-semibold bg-third px-3 rounded-3xl" className="transition-all duration-500">tâm linh</Link>
        <Link to="/khoa-hoc/" activeClassName="font-semibold bg-third px-3 rounded-3xl" className="transition-all duration-500">khoa học</Link>
        <Link to="/song-dep/" activeClassName="font-semibold bg-third px-3 rounded-3xl" className="transition-all duration-500">sống đẹp</Link>
      </div>
      <div className="xl:hidden z-50">
        <Hamburger />
      </div>
    </div>
  )
}

export default Header

// function Header() {
//     return (
//       <div className='flex flex-row place-content-between place-items-center h-28 px-3 sm:px-5 md:px-10 xl:px-16 2xl:px-24'>
//         <Link to='/'>Bọ rùa tỉnh thức</Link>
//         <div className='xl:flex flex-row gap-14 hidden '>
//           <Link to='/'>home</Link>
//           <Link to='/trai-nghiem/'>trải nghiệm</Link>
//           <Link to='/tam-linh/'>tâm linh</Link>
//           <Link to='/khoa-hoc/'>khoa học</Link>
//           <Link to='/song-dep/'>sống đẹp</Link>
//         </div>
//         <div className='xl:hidden'>
//           <Hamburger />
//         </div>
//       </div>
//     )
//   }

//   export default Header
